

import React, { Component } from 'react';
import { ReactComponent as ReactLogo } from './egg-logo.svg';
import styled, { keyframes } from 'styled-components'
/*
const rotate = keyframes`
  from {
    transform: rotate3d(0,0,0,75deg);
  }
  to {
    transform: rotate3d(0,1,0,360deg);
  }
`;*/
const fade = keyframes`
0% {
stroke:#0cf;

}
10%{
  stroke:#FFF;
}
20%{
  stroke:#0cf;
}
30%{stroke: #FFF;
    }
40%{
    stroke: #0cf}
50% {
  
 stroke:#FFF;
}
60%{stroke: #0cf}
70%{
  stroke:#FFF;
}
80%{stroke: #0cf;}
90%{stroke: #FFF;}
100%{
  stroke:#0cf;
}
`;

/*const pulse = keyframes`
0% {
  transform: scale(0);
  opacity: 1;
  transform-origin: center;
}
100% {
  transform: scale(3);
  opacity: 0;
  transform-origin: center;
}
`;*/
const dash = keyframes`
  to {
    stroke-dashoffset: 1000;
  }
`;
const dash2 = keyframes`
  from {
    stroke-dashoffset: 1000;
  }
`;
const dash3 = keyframes`
  to {
    stroke-dashoffset: 150;
  }
`;
const StyledLogo = styled(ReactLogo)`
height:25rem;
width:50rem;
display:block;
margin:auto;
.egg-text{

  animation: ${fade} infinite 30s linear;
    }
.outter{ stroke-dasharray: 10;
  animation: ${dash2} 20s infinite linear; }
.inner{
  stroke-dasharray: 10;
  animation: ${dash} infinite 20s linear;
  &:hover{
    animation-play-state: paused;
    cursor:pointer;
}
}
`

class App extends Component {
  render() {
    return (
      <StyledLogo />
    );
  }
}

export default App;